import { Html } from "@react-three/drei";

export const Loader = ({children}: any) => {
    return (
        <Html>
            Loading...
        </Html>
    )
}


